<template>
  <div class="app-container">
    <ShowTitle text="使用條款" type="title" />
  </div>
</template>

<script>
import mainCommon from "@/utils/mainCommon";
import configCommon from "@/utils/configCommon";
// import { userProfile, userEdit } from "@/api/user";
// import { userAvatarChange } from "@/api/user";
// import Cookies from "js-cookie";

export default {
  mixins: [mainCommon],
  data() {
    return {
      // imageUrl: null,
      // action: `${configCommon.apiHost}/api/upload/image`,
      // submitLoading: false,
      // dataObj: {},
    };
  },
};
</script>

<style lang="less" scoped>
</style>